var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-900 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_vm._m(0),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-px-6"},[_c('a',{staticClass:"tw-text-blue-500 tw-text-sm tw-mb-2 tw-cursor-pointer hover:tw-text-blue-400 back",attrs:{"href":"#/reconciliation/1"}},[_c('fa',{staticClass:"tw-text-inherit",attrs:{"icon":"fa-regular fa-chevron-right","flip":"horizontal"}}),_vm._v(" Back to Reconciliation Details ")],1)])]),_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.runningComparisons,"isLoading":!!_vm.isLoading,"no-data-message":"There are no reconciliations to display."},scopedSlots:_vm._u([{key:"td-expand",fn:function(ref){return [_c('td',{staticClass:"tw-text-left"},[_c('div',[_c('fa',{staticClass:"tw-px-2",attrs:{"icon":"fa-solid fa-plus"}})],1)])]}},{key:"td-date",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-text-left tw-align-top"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap tw-align-top"},[_vm._v(" "+_vm._s(rec.date)+" ")])])]}},{key:"td-blockchain-balance",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-align-top"},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.blockchainBalance)+" ")]),(rec.expanded)?_c('div',[_vm._v("Blah ditty blah")]):_vm._e()])]}},{key:"td-system-balance",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-align-top"},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.systemBalance)+" ")]),(rec.expanded)?_c('div',[_vm._v("Blah ditty blah")]):_vm._e()])]}},{key:"td-delta",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-align-top"},[_vm._v(" "+_vm._s(rec.delta)+" ")])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium"},[_vm._v("Comparison")])])}]

export { render, staticRenderFns }