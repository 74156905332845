var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-900 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_vm._m(0),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-px-6"},[_c('a',{staticClass:"tw-text-blue-500 tw-text-sm tw-mb-2 tw-cursor-pointer hover:tw-text-blue-400 back",attrs:{"href":"#/reconciliation"}},[_c('fa',{staticClass:"tw-text-inherit",attrs:{"icon":"fa-regular fa-chevron-right","flip":"horizontal"}}),_vm._v(" Back to Reconciliations ")],1)])]),(_vm.$route.params.recId !== '1')?_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-justify-between tw-flex"},[_vm._m(1),_c('div',{staticClass:"tw-w-1/4"},[_c('ui-select2',{attrs:{"key-only":"","items":['All', 'Any Variance', 'Hard Variance']},model:{value:(_vm.transactionVariance),callback:function ($$v) {_vm.transactionVariance=$$v},expression:"transactionVariance"}})],1)]),_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.transaction_headers,"items":_vm.transactionItems,"isLoading":!!_vm.isLoading,"no-data-message":"There are no reconciliations to display.","fixed-table":true},scopedSlots:_vm._u([{key:"td-id",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-text-left",class:_vm.getVarianceClass(rec.variance)},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":rec.id}})],1)])]}},{key:"td-amount",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec.variance)},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-px-4"},[_c('div',[_vm._v(_vm._s(rec.comparisons.find(function (m) { return m.name === 'amount'; }).leftValue))]),_c('div',[_vm._v(_vm._s(rec.comparisons.find(function (m) { return m.name === 'amount'; }).rightValue))])])])]}},{key:"td-ticker",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec.variance)},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-px-4"},[_c('div',[_vm._v(_vm._s(rec.comparisons.find(function (m) { return m.name === 'ticker'; }).leftValue))]),_c('div',[_vm._v(_vm._s(rec.comparisons.find(function (m) { return m.name === 'ticker'; }).rightValue))])])])]}},{key:"td-timestamp",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec.variance)},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-px-4 tw-whitespace-nowrap"},[_c('div',[_vm._v(_vm._s(_vm.toOrgLocalTime(rec.comparisons.find(function (m) { return m.name === 'timestampSEC'; }).leftValue)))]),_c('div',[_vm._v(_vm._s(_vm.toOrgLocalTime(rec.comparisons.find(function (m) { return m.name === 'timestampSEC'; }).rightValue)))])])])]}},{key:"td-actions",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec.variance)},[(rec.hasComments)?_c('div',{staticClass:"tw-cursor-pointer tw-inline"},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300",attrs:{"icon":"fa-regular fa-comments"}})],1):_c('div',{staticClass:"tw-cursor-pointer tw-inline"},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300",attrs:{"icon":"fa-solid fa-comments"}})],1),_c('div',{staticClass:"tw-cursor-pointer tw-inline",on:{"click":_vm.downloadFile}},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300 tw-px-3",attrs:{"icon":"fa-regular fa-file-excel"}})],1)])]}},{key:"td-reconciled",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec.variance)},[(rec.reconciled)?_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-regular fa-circle-check"}}):(rec.isLoading)?_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-regular fa-refresh"}}):_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-regular fa-close"},on:{"click":function($event){return _vm.markReconciled(rec)}}})],1)]}}],null,false,651622709)})],1)]):_vm._e(),(_vm.$route.params.recId === '1')?_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('span',{staticClass:"tw-text-lg tw-capitalize tw-font-medium"},[_vm._v("Balance Comparisons")]),_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reconciliations,"isLoading":!!_vm.isLoading,"no-data-message":"There are no reconciliations to display."},scopedSlots:_vm._u([{key:"td-ticker",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(rec.ticker)+" ")])])]}},{key:"td-blockchain-balance",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.blockchainBalance)+" ")])])]}},{key:"td-system-balance",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.systemBalance)+" ")])])]}},{key:"td-delta",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('a',{staticClass:"tw-text-blue-500 tw-underline",attrs:{"href":("#/reconciliation/" + (rec.id) + "/comparison")}},[_vm._v(_vm._s(rec.delta))])])]}},{key:"td-actions",fn:function(ref){
var rec = ref.item;
return [_c('td',[(rec.hasComments)?_c('div',{staticClass:"tw-cursor-pointer tw-inline"},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300",attrs:{"icon":"fa-regular fa-comments"}})],1):_c('div',{staticClass:"tw-cursor-pointer tw-inline"},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300",attrs:{"icon":"fa-solid fa-comments"}})],1),_c('div',{staticClass:"tw-cursor-pointer tw-inline",on:{"click":_vm.downloadFile}},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300 tw-px-3",attrs:{"icon":"fa-regular fa-file-excel"}})],1)])]}}],null,false,1828977083)})],1)]):_vm._e(),(_vm.$route.params.recId === '1')?_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-flex"},[_vm._m(2),_c('div',{staticClass:"tw-flex"},[(_vm.showRuns)?_c('ui-button',{nativeOn:{"click":function($event){$event.stopPropagation();_vm.showNewDialog = true}}},[_vm._v("Upload File")]):_vm._e()],1)]),_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.fileHeaders,"items":_vm.files,"isLoading":!!_vm.isLoading,"no-data-message":"There are no reconciliations to display."},scopedSlots:_vm._u([{key:"td-name",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(rec.name)+" ")])])]}},{key:"td-uploaded",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.uploaded)+" ")])])]}},{key:"td-uploaded-by",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.uploadedBy)+" ")])])]}},{key:"td-actions",fn:function(ref){return [_c('td',[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300 tw-px-3",attrs:{"icon":"fa-regular fa-cloud-arrow-down"}}),_c('fa',{staticClass:"tw-text-lg tw-text-primary-300 tw-px-3",attrs:{"icon":"fa-regular fa-trash"}})],1)]}}],null,false,2095909254)})],1)]):_vm._e(),_c('iframe',{staticStyle:{"display":"none"},attrs:{"id":"downloadFrame"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium"},[_vm._v("Reconciliation Details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"tw-text-lg tw-capitalize tw-font-medium"},[_vm._v("Transaction Comparisons")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex-1"},[_c('span',{staticClass:"tw-text-lg tw-capitalize tw-font-medium"},[_vm._v("Supporting Files")])])}]

export { render, staticRenderFns }