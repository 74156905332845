





















































































import axios from 'axios';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Coins,
  Contact,
  ReconciliationStatus,
  Transaction,
  TransactionsResult,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { RegisterQuery } from '@/queries/registerQuery';
import { stringifyError } from '@/utils/error';
import { isDefined } from '@/utils/guards';

import { baConfig } from '../../../config';

@Component({
  components: {
    UiDatePicker2,
    UiSelect2,
    UiDataTable,
    UiModal,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
  },
})
export default class Reconciliation extends BaseVue {
  reconciliations: Record<string, unknown>[] = []; // ?: { key: string; value: string }[] = [];
  runs?: any[] = [];
  rows?: any[] = [];
  runId?: string;

  public reconciliation: string | null = null;

  public showRuns = true;

  public start = '';
  public end = '';
  public recTemplate = '';

  public showCreate = false;
  // public get headers() {
  //   return Object.values(this.recColumns).map((x) => x.heading);
  //   // if (this.showRuns) {
  //   //   return Object.values(this.recColumns).map((x) => x.heading);
  //   // } else {
  //   //   return Object.values(this.rowsColumns).map((x) => x.heading);
  //   // }
  // }

  // public onDateChange(field: string, date: string) {
  //
  //   this.[field] = date;
  // }
  // isValidDate(dateString: string) {
  //   // Parse the date string into a Date object
  //   const dateObj = new Date(dateString);
  //   // Check if the date object is valid and the string matches the expected format
  //   return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  // }

  public createRec() {
    const r = this.reconciliations.filter((m) => m.id === '1');
    // r.show = true;

    this.showNewDialog = false;
  }

  public showNewDialog = false;

  public headers = [
    { id: 'date', label: 'Date', defaultVisibility: true, defaultWidth: '100px' },
    { id: 'reconciliation', label: 'Reconciliation', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'status', label: 'Status', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'approver', label: 'Approver', defaultVisibility: true, defaultWidth: '94px' },
    { id: 'actions', label: '', defaultVisibility: true, defaultWidth: '83px' },
  ];

  // public headers = {
  //   date: { heading: 'Date', size: 100 / 20 },
  //   type: { heading: 'Type', size: 100 / 20 },
  //   status: { heading: 'Status', size: 100 / 20 },
  //   approver: { heading: 'Approver', size: 100 / 20 },
  //   actions: { heading: '', size: 100 / 20 },
  // };

  public rowsColumns = {
    id: { heading: 'Id', size: 100 / 10 },
    reconciled: { heading: 'Reconciled', size: 100 / 10 },
  };

  public allSelected = false;
  public isLoading = 0;
  public showFilters = false;
  public skipQuery = true;

  public endDate = new Date().toISOString().substring(0, 10);

  public vars = {
    filter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletId: 'All',
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  async mounted() {
    this.isLoading = 1;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations-runs`;
      const resp = await axios.get(url, { withCredentials: true });
      this.reconciliations = [
        {
          id: '1',
          date: '05/01/2022 - 05/31/2022',
          reconciliation: 'Monthly Client Balance Rec',
          status: 'Open',
          approver: 'Pat White',
          complete: false,
        },
      ];

      for (const run of resp.data.items) {
        this.reconciliations.push({
          id: run.id,
          date: moment.unix(run.createdSEC).format('MM/DD/YYYY'),
          reconciliation: run.name,
          approver: 'Pat White',
          status: 'Open',
          complete: run.status === 'Complete',
        });
      }

      // const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations`;
      // const resp = await axios.get(url, { withCredentials: true });
      // this.reconciliations = resp.data.items.map((m: any) => {
      //   return {
      //     key: m.id,
      //     value: m.name,
      //   };
      // });
    } finally {
      this.isLoading = 0;
    }
  }

  public friendlyStatus(status: string) {
    switch (status) {
      case 'InProgress':
        return 'Reconciliation In Progress';
      case 'Loading':
        return 'Data Loading';
      default:
        return 'Unknown';
    }
  }

  async run() {
    this.isLoading = 1;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations/${this.reconciliation}/runs`;
      const resp = await axios.post(url, {}, { withCredentials: true });
      if (resp.data && resp.data.items) {
        this.reconciliations = resp.data.items.map((m: any) => {
          return {
            key: m.id,
            value: m.name,
          };
        });

        await this.loadRuns();
      }
    } finally {
      this.isLoading = 0;
    }
  }

  public async markReconciled(row: any) {
    row.isLoading = true;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations/${this.reconciliation}/runs/${
        this.runId
      }/rows/${row.id}`;
      const u = {
        reconciled: !row.reconciled,
      };
      const resp = await axios.put(url, { withCredentials: true });
    } finally {
      row.reconciled = !row.reconciled;
      row.isLoading = false;
    }
  }

  public getVarianceClass(c: any) {
    if (c.variance === 'none') {
      return 'tw-bg-green-50';
    } else if (c.variance === 'near-match') {
      return 'tw-bg-yellow-100';
    } else {
      return 'tw-bg-red-100';
    }
  }

  public getRowVarianceClass(row: any) {
    if (row.variance === 'none' || row.variance === 'near-match') {
      return 'tw-bg-green-50';
    } else {
      return 'tw-bg-red-100';
    }
  }

  async viewRun(id: string) {
    this.isLoading = 1;
    this.showRuns = false;
    this.runId = id;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations/${
        this.reconciliation
      }/runs/${id}/rows`;
      const resp = await axios.get(url, { withCredentials: true });
      this.rows = resp.data.items;
      if (this.rows) {
        if (this.rows.length > 0) {
          for (const c of this.rows[0].comparisons) {
            (this.rowsColumns as any)[c.name as string] = { heading: c.name, size: 100 / 20 };
          }
        }
      }
    } finally {
      this.isLoading = 0;
    }
  }

  @Watch('reconciliation')
  async loadRuns() {
    this.isLoading = 1;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations/${this.reconciliation}/runs`;
      const resp = await axios.get(url, { withCredentials: true });
      this.runs = resp.data.items;
    } finally {
      this.isLoading = 0;
    }
  }

  public showMenuOn: Transaction | null = null;
  public expandedTxn: Transaction | null = null;
  public showCreateManualTxnModal = false;
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: Transaction | null = null;
  public deleteDialog = false;
  public isSoftLoading = 0;
  public walletId = 'select';
  public ticker: string | null = null;

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public async refresh() {
    await Promise.all([this.$apollo.queries.register.refetch()]);

    this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
    this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
  }

  toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }
}
