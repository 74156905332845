














































































































































































































import axios from 'axios';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Coins,
  Contact,
  ReconciliationStatus,
  Transaction,
  TransactionsResult,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import { RegisterQuery } from '@/queries/registerQuery';
import { stringifyError } from '@/utils/error';
import { isDefined } from '@/utils/guards';

import { baConfig } from '../../../config';

@Component({
  components: {
    UiSelect2,
    UiDataTable,
    UiModal,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    UiTruncateText,
  },
})
export default class Explore extends BaseVue {
  reconciliations: Record<string, unknown>[] = [];
  transaction_reconciliations: Record<string, unknown>[] = [];
  files: Record<string, unknown>[] = [];
  runs?: any[] = [];
  rows?: any[] = [];
  runId?: string;

  public reconciliation: string | null = null;

  public showRuns = true;

  // public get headers() {
  //   return Object.values(this.recColumns).map((x) => x.heading);
  //   // if (this.showRuns) {
  //   //   return Object.values(this.recColumns).map((x) => x.heading);
  //   // } else {
  //   //   return Object.values(this.rowsColumns).map((x) => x.heading);
  //   // }
  // }

  public showNewDialog = false;

  public headers = [
    { id: 'ticker', label: 'Ticker', defaultVisibility: true, defaultWidth: '100px' },
    { id: 'blockchain-balance', label: 'Blockchain Balance', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'system-balance', label: 'Internal System Balance', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'delta', label: 'Delta', defaultVisibility: true, defaultWidth: '94px' },
    { id: 'actions', label: '', defaultVisibility: true, defaultWidth: '94px' },
  ];

  public transaction_headers = [
    { id: 'reconciled', label: '', defaultVisibility: true, defaultWidth: '10px' },
    { id: 'id', label: 'Id', defaultVisibility: true, defaultWidth: '50px' },
    { id: 'amount', label: 'Amount', defaultVisibility: true, defaultWidth: '50px' },
    { id: 'ticker', label: 'Ticker', defaultVisibility: true, defaultWidth: '50px' },
    { id: 'timestamp', label: 'Timestamp', defaultVisibility: true, defaultWidth: '100px' },
    { id: 'actions', label: '', defaultVisibility: true, defaultWidth: '30px' },
  ];

  public fileHeaders = [
    { id: 'name', label: 'Name', defaultVisibility: true, defaultWidth: '100px' },
    { id: 'uploaded', label: 'Uploaded', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'uploaded-by', label: 'Uploaded By', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'actions', label: '', defaultVisibility: true, defaultWidth: '94px' },
  ];

  public transactionVariance = 'All';

  async downloadFile() {
    const downloadFrame = document.getElementById('downloadFrame') as HTMLIFrameElement;
    downloadFrame.src = 'http://localhost:8082/052022_Monthly_Client_Balance_Rec.xlsx';
  }

  // public headers = {
  //   date: { heading: 'Date', size: 100 / 20 },
  //   type: { heading: 'Type', size: 100 / 20 },
  //   status: { heading: 'Status', size: 100 / 20 },
  //   approver: { heading: 'Approver', size: 100 / 20 },
  //   actions: { heading: '', size: 100 / 20 },
  // };

  public rowsColumns = {
    id: { heading: 'Id', size: 100 / 10 },
    reconciled: { heading: 'Reconciled', size: 100 / 10 },
  };

  public allSelected = false;
  public isLoading = 0;
  public showFilters = false;
  public skipQuery = true;

  public endDate = new Date().toISOString().substring(0, 10);

  public vars = {
    filter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletId: 'All',
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public get transactionItems() {
    switch (this.transactionVariance) {
      case 'All': {
        return this.transaction_reconciliations;
      }
      case 'Any Variance': {
        return this.transaction_reconciliations.filter((m) => m.variance === 'near-match' || m.variance === 'mismatch');
      }
      case 'Hard Variance': {
        return this.transaction_reconciliations.filter((m) => m.variance === 'mismatch');
      }
    }
  }

  async mounted() {
    this.isLoading = 1;
    try {
      this.reconciliations = [
        {
          ticker: 'ETH',
          blockchainBalance: '0.33',
          systemBalance: '0.34',
          delta: '0.01',
          hasComments: false,
        },
      ];

      this.files = [];
      if (this.$route.params.recId === '1') {
        this.files.push({
          name: 'ETH Reconciling Items.xlsx',
          uploaded: '12/02/2023',
          uploadedBy: 'Pat White',
        });
      } else {
        const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations-runs/${
          this.$route.params.recId
        }/rows`;
        const resp = await axios.get(url, { withCredentials: true });
        this.transaction_reconciliations = resp.data.items;
      }
    } finally {
      this.isLoading = 0;
    }
  }

  public friendlyStatus(status: string) {
    switch (status) {
      case 'InProgress':
        return 'Reconciliation In Progress';
      case 'Loading':
        return 'Data Loading';
      default:
        return 'Unknown';
    }
  }

  async run() {
    this.isLoading = 1;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations/${this.reconciliation}/runs`;
      const resp = await axios.post(url, {}, { withCredentials: true });
      if (resp.data && resp.data.items) {
        this.reconciliations = resp.data.items.map((m: any) => {
          return {
            key: m.id,
            value: m.name,
          };
        });

        await this.loadRuns();
      }
    } finally {
      this.isLoading = 0;
    }
  }

  public async markReconciled(row: any) {
    row.isLoading = true;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations/${this.reconciliation}/runs/${
        this.runId
      }/rows/${row.id}`;
      const u = {
        reconciled: !row.reconciled,
      };
      const resp = await axios.put(url, { withCredentials: true });
    } finally {
      row.reconciled = !row.reconciled;
      row.isLoading = false;
    }
  }

  public getVarianceClass(variance: any) {
    if (variance === 'none') {
      return 'tw-bg-green-50';
    } else if (variance === 'near-match') {
      return 'tw-bg-yellow-100';
    } else {
      return 'tw-bg-red-100';
    }
  }

  public getRowVarianceClass(row: any) {
    if (row.variance === 'none' || row.variance === 'near-match') {
      return 'tw-bg-green-50';
    } else {
      return 'tw-bg-red-100';
    }
  }

  async viewRun(id: string) {
    this.isLoading = 1;
    this.showRuns = false;
    this.runId = id;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations/${
        this.reconciliation
      }/runs/${id}/rows`;
      const resp = await axios.get(url, { withCredentials: true });
      this.rows = resp.data.items;
      if (this.rows) {
        if (this.rows.length > 0) {
          for (const c of this.rows[0].comparisons) {
            (this.rowsColumns as any)[c.name as string] = { heading: c.name, size: 100 / 20 };
          }
        }
      }
    } finally {
      this.isLoading = 0;
    }
  }

  @Watch('reconciliation')
  async loadRuns() {
    this.isLoading = 1;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliations/${this.reconciliation}/runs`;
      const resp = await axios.get(url, { withCredentials: true });
      this.runs = resp.data.items;
    } finally {
      this.isLoading = 0;
    }
  }

  public showMenuOn: Transaction | null = null;
  public expandedTxn: Transaction | null = null;
  public showCreateManualTxnModal = false;
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: Transaction | null = null;
  public deleteDialog = false;
  public isSoftLoading = 0;
  public walletId = 'select';
  public ticker: string | null = null;

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public async refresh() {
    await Promise.all([this.$apollo.queries.register.refetch()]);

    this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
    this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
  }

  toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }
}
