var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-font-sans"},[_c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium tw-font-heading"},[_vm._v("Reconciliation Templates")]),_c('ui-button',[_vm._v("New Reconciliation Template")])],1)]),_c('div',{staticClass:"tw-w-full tw-border-b tw-border-gray-300"},[_c('div',{staticClass:"tw-pl-4 sm:tw-pl-6 lg:tw-pl-8 tw-text-gray-800 tw-flex tw-items-center tw-justify-between tw-pt-1 tw-mb-1"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-neutral-500 tw-text-primary-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-arrow-rotate-right"},on:{"click":_vm.refresh}})],1)])]),_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.templates,"isLoading":!!_vm.isLoading,"no-data-message":"There are no template to display."},scopedSlots:_vm._u([{key:"td-id",fn:function(ref){
var temp = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(temp.id)+" ")])])]}},{key:"td-type",fn:function(ref){
var temp = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(temp.type)+" ")])])]}},{key:"td-name",fn:function(ref){
var temp = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(temp.name)+" ")])])]}},{key:"td-cadence",fn:function(ref){
var temp = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(temp.cadence)+" ")])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }