






























































import axios from 'axios';
import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import CostBasis from '@/components/clients/tabs/costBasis.vue';
import Transactions from '@/components/clients/tabs/transactions.vue';
import Users from '@/components/clients/tabs/users.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiSelect from '@/components/ui/UiSelect.vue';

import { baConfig } from '../../../config';

export interface Client {
  orgId: string;
  name: string;
}

export type RecTemplate = {
  id: string;
  name: string;
  type: string;
  cadence: string;
};

@Component({
  components: {
    UiButton,
    UiDataTable,
    UiSelect,
    Transactions,
    CostBasis,
    Users,
  },
})
export default class Clients extends BaseVue {
  // public clients: ApiSvcOrg[] = [];
  public selectedClient: string | null = null;
  public selectedTab = 'transactions';

  // public get clientList() {
  //   const clientList: Record<string, string> = {};
  //   for (const c of this.clients) {
  //     clientList[c.name] = c.id!;
  //   }
  //   return clientList;
  // }

  public templates: RecTemplate[] = [];

  public isLoading = false;

  public headers = [
    { id: 'id', label: 'Id', defaultVisibility: true, defaultWidth: '52px' },
    // { id: 'type', label: 'Type', defaultVisibility: true, defaultWidth: '152px' },
    { id: 'name', label: 'Name', defaultVisibility: true, defaultWidth: '252px' },
    { id: 'cadence', label: 'Cadence', defaultVisibility: true, defaultWidth: '152px' },
  ];

  async refresh() {
    return this.loadTemplates();
  }

  async mounted() {
    await this.loadTemplates();
    // const clientApi = new ClientDataApi(undefined, baConfig.getFriendlyApiUrl());
    // const resp = await clientApi.getClients(this.orgId, { withCredentials: true });
    // this.clients = resp.data.items;
    // this.selectedClient = this.clientList[0];
  }

  private async loadTemplates() {
    this.isLoading = true;
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/v2/orgs/${this.orgId}/reconciliation-templates`;
      const resp = await axios.get(url, { withCredentials: true });
      this.templates = resp.data.items.map((m: any) => {
        return {
          id: m.id,
          name: m.name,
          cadence: m.cadence,
        };
      });
    } finally {
      this.isLoading = false;
    }

    this.templates.push({
      id: 'ko1245nn12302215',
      name: 'Monthly Client Balance Rec',
      cadence: 'Monthly',
      type: 'Balance',
    });
  }

  public getTabClass(id: string) {
    if (id === this.selectedTab) {
      return 'tw-border-primary-300 tw-text-gray-700 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    } else {
      return 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    }
  }
}
