var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-900 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_vm._m(0),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-px-6"},[_c('a',{staticClass:"tw-text-blue-500 tw-text-sm tw-mb-2 tw-cursor-pointer hover:tw-text-blue-400 back",attrs:{"href":"#/reconciliation"}},[_c('fa',{staticClass:"tw-text-inherit",attrs:{"icon":"fa-regular fa-chevron-right","flip":"horizontal"}}),_vm._v(" Back to Reconciliations ")],1)])]),_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-flex"},[_vm._m(1),_c('div',{staticClass:"tw-flex"},[_c('ui-button',{attrs:{"disabled":_vm.dataLoading,"loading":_vm.dataLoading},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.loadData.apply(null, arguments)}}},[_vm._v("Reload Data")])],1)]),_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reconciliations,"isLoading":!!_vm.isLoading,"no-data-message":_vm.isDataLoaded ? 'There are no reconciliations to display.' : 'Reconciliation Data Not Loaded'},scopedSlots:_vm._u([{key:"td-name",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(rec.name)+" ")])])]}},{key:"td-total",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.total)+" ")])])]}},{key:"td-matched",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.matched)+" ")])])]}},{key:"td-details",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('a',{staticClass:"tw-text-blue-500 tw-underline",attrs:{"href":("#/reconciliations/" + (rec.id) + "/comparison")}},[_vm._v("Details")])])]}},{key:"td-actions",fn:function(ref){
var rec = ref.item;
return [_c('td',[(rec.hasComments)?_c('div',{staticClass:"tw-cursor-pointer tw-inline"},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300",attrs:{"icon":"fa-regular fa-comments"}})],1):_c('div',{staticClass:"tw-cursor-pointer tw-inline"},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300",attrs:{"icon":"fa-solid fa-comments"}})],1),_c('div',{staticClass:"tw-cursor-pointer tw-inline",on:{"click":_vm.downloadFile}},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300 tw-px-3",attrs:{"icon":"fa-regular fa-file-excel"}})],1)])]}}])})],1)]),_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-flex"},[_vm._m(2),_c('div',{staticClass:"tw-flex"},[(_vm.showRuns)?_c('ui-button',{nativeOn:{"click":function($event){$event.stopPropagation();_vm.showNewDialog = true}}},[_vm._v("Upload File")]):_vm._e()],1)]),_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.fileHeaders,"items":_vm.files,"isLoading":!!_vm.isLoading,"no-data-message":"There are no files to display"},scopedSlots:_vm._u([{key:"td-name",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(rec.name)+" ")])])]}},{key:"td-uploaded",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.uploaded)+" ")])])]}},{key:"td-uploaded-by",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.uploadedBy)+" ")])])]}},{key:"td-actions",fn:function(ref){return [_c('td',[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300 tw-px-3",attrs:{"icon":"fa-regular fa-cloud-arrow-down"}}),_c('fa',{staticClass:"tw-text-lg tw-text-primary-300 tw-px-3",attrs:{"icon":"fa-regular fa-trash"}})],1)]}}])})],1)]),_c('iframe',{staticStyle:{"display":"none"},attrs:{"id":"downloadFrame"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium"},[_vm._v("Reconciliation Details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex-1"},[_c('span',{staticClass:"tw-text-lg tw-capitalize tw-font-medium"},[_vm._v("Reconciliations")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex-1"},[_c('span',{staticClass:"tw-text-lg tw-capitalize tw-font-medium"},[_vm._v("Supporting Files")])])}]

export { render, staticRenderFns }