var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-900 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_vm._m(0),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-px-6"},[_c('a',{staticClass:"tw-text-blue-500 tw-text-sm tw-mb-2 tw-cursor-pointer hover:tw-text-blue-400 back",attrs:{"href":"#/reconciliation"}},[_c('fa',{staticClass:"tw-text-inherit",attrs:{"icon":"fa-regular fa-chevron-right","flip":"horizontal"}}),_vm._v(" Back to Reconciliations ")],1)])]),_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-justify-between tw-flex"},[_vm._m(1),_c('div',{staticClass:"tw-w-1/4"},[_c('ui-select2',{attrs:{"key-only":"","items":['All', 'Any Variance', 'Hard Variance']},model:{value:(_vm.transactionVariance),callback:function ($$v) {_vm.transactionVariance=$$v},expression:"transactionVariance"}})],1)]),_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.transaction_headers,"items":_vm.transactionItems,"isLoading":!!_vm.isLoading,"no-data-message":"There are no items to display.","fixed-table":true},scopedSlots:_vm._u([{key:"td-id",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-text-left",class:_vm.getVarianceClass(rec[7])},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":rec[0]}})],1)])]}},{key:"td-amount",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec[3])},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-px-4"},[_c('div',[_vm._v(_vm._s(rec[1]))]),_c('div',[_vm._v(_vm._s(rec[2]))])])])]}},{key:"td-clientId",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec[6])},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-px-4"},[_c('div',[_vm._v(_vm._s(rec[4]))]),_c('div',[_vm._v(_vm._s(rec[5]))])])])]}},{key:"td-actions",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec[7])},[(rec.hasComments)?_c('div',{staticClass:"tw-cursor-pointer tw-inline"},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300",attrs:{"icon":"fa-regular fa-comments"}})],1):_c('div',{staticClass:"tw-cursor-pointer tw-inline"},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300",attrs:{"icon":"fa-solid fa-comments"}})],1),_c('div',{staticClass:"tw-cursor-pointer tw-inline",on:{"click":_vm.downloadFile}},[_c('fa',{staticClass:"tw-text-lg tw-text-primary-300 tw-px-3",attrs:{"icon":"fa-regular fa-file-excel"}})],1)])]}},{key:"td-reconciled",fn:function(ref){
var rec = ref.item;
return [_c('td',{class:_vm.getVarianceClass(rec[7])},[(rec[7])?_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-regular fa-circle-check"}}):(rec.isLoading)?_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-regular fa-refresh"}}):_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-regular fa-close"},on:{"click":function($event){return _vm.markReconciled(rec)}}})],1)]}}])})],1)]),_c('iframe',{staticStyle:{"display":"none"},attrs:{"id":"downloadFrame"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium"},[_vm._v("Reconciliation Details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"tw-text-lg tw-capitalize tw-font-medium"},[_vm._v("Transaction Comparisons")])])}]

export { render, staticRenderFns }